import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createBlock(_component_el_breadcrumb, { separator: "/" }, {
    default: _withCtx(() => [
      _createVNode(_TransitionGroup, { name: "breadcrumb" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crumbList, (route, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (route.name != 'home')
                ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                    key: route.name
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        style: _normalizeStyle({ color: i == _ctx.crumbList.length - 1 ? 'var(--el-color-primary)' : '' })
                      }, _toDisplayString(route.meta.name), 5)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}