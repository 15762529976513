
import { useStore } from "@/store/index";
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "HeaderBreadcrumb",
  setup() {
    // 获取state
    const { state } = useStore();
    // 获取面包屑
    const crumbList = computed(() => state.authData.crumbList);

    return {
      crumbList
    };
  }
});
